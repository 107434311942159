
/* 공통 */
.contents-wrap { 
    width: 100%; 
    background-color: #f5f5f5; 
    padding-top: 24px;
}

/* 페이지네이션 */
.pagenation-wrap { 
    width: 100%; 
    display: flex; 
    justify-content: center; 
}

@media (max-width: 1400px) {
    .pagenation-wrap {
        padding-right: 20px;
        justify-content: right;
    }
}

.pagination { justify-content: center; }
.page-nav { display: flex; justify-content: center;  gap: 18px; }
.page-nav li a { width: 22px; height: 22px; display: flex; justify-content: center; align-items: center;  font-size: 14px; font-family: "Pretendard"; }
.page-nav li a.now { opacity: 1; font-size: 14px; font-weight: 300; color: #fff; border-radius: 2px; background-color: #1890ff; }
.page-nav li a .xi-angle-left { color: rgba(0, 0, 0, 0.25); }

/* 공통 - 상단 */
.container .title-wrap { 
    position: fixed;
    width: calc(100% - 208px);
    min-width: 340px;
    height: 80px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 9;
} 
.container .title-wrap .title { display: block; font-size: 20px; color: rgba(0, 0, 0, 0.85); font-weight: normal;}

/* 공통 - 상단 버튼 그룹 */
.container .title-wrap .btn-wrap { display: flex; gap:16px;}
.container .title-wrap .choic-del-btn { display: flex; width: 96px; height: 40px; background-color: #d8d8d8; display: flex; align-items: center; justify-content: center; cursor: pointer;}
.container .title-wrap .choic-del-btn span { font-size: 14px; font-weight: 500; color: #9b9b9b; }
.container .title-wrap .add-btn { display: flex;  width: 82px; height: 32px; background-color: #1890ff; display: flex; align-items: center; justify-content: center; border-radius: 2px;}
.container .title-wrap .add-btn span { font-size: 14px; font-weight: normal; color: #fff; }
.container .title-wrap .add-btn i { font-size: 14px; color: #fff;} 
.container .page-route-wrap { width: 100%;  padding: 24px 24px 16px 24px; background-color: #fff;}
.container .page-route-wrap .route-text {   font-size: 14px; font-weight: 300;  color: #8b8b8b; }
/* 검색버튼 있는 경우 */
.container .title-wrap .right-wrap { display: flex; height: 32px;}
.container .title-wrap .right-wrap .search-wrap { display: flex; margin-right: 24px;}

.searchTr {
    border-bottom: 1px solid #e8e8e8; 
}

.container .title-wrap  .search { 
    position: relative; width: 272px; height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.container .title-wrap  .search  input { width: 100%; height: 100%; border: 1px solid #d9d9d9; border-radius: 2px;  font-size: 11px; padding: 5px 0 5px 8px;  }
.container .title-wrap  .search  input::placeholder { font-size: 11px;  color: rgba(0, 0, 0, 0.25); }
.container .title-wrap  .search i { 
    position : absolute;  
    width: 14px;  
    right: 12px;  
    margin: 0; 
    color: rgba(0, 0, 0, 0.25); 
}
/* 셀렉트박스 디자인 */
.container .title-wrap .right-wrap .search-wrap .select {  display: inline-block; width: 120px; height: 100%; border: 1px solid #d9d9d9; border-radius: 2px; margin-right: 12px;}
.container .title-wrap .right-wrap .search-wrap .select .selected { display: flex;  justify-content: space-between; padding: 5px 0 5px 8px; align-items: center; cursor: pointer; margin-right: 12px;}
.container .title-wrap .right-wrap .search-wrap .select .selected .selected-value { max-width: 104px; font-size: 11px; font-weight: normal;  color: rgba(0, 0, 0, 0.25);}
.container .title-wrap .right-wrap .search-wrap .select .selected i { font-size: 20px; color: #c4c4c4;}
.container .title-wrap .right-wrap .search-wrap .select ul { display: none; width: 120px;  border: 1px solid #d9d9d9; position: absolute; background: #fff;   margin: -1Px 0 0 -1px;  cursor: pointer;  }
.container .title-wrap .right-wrap .search-wrap .select.active ul { display: initial; }
.container .title-wrap .right-wrap .search-wrap .select ul li { padding: 8px 5px;  font-size: 11px; font-weight: normal;  color: rgba(0, 0, 0, 0.25); }
.container .title-wrap .right-wrap .search-wrap .select ul li:hover {  background: #d9d9d9; color: black; }
.container .title-wrap .right-wrap .search-wrap .search { position: relative; width: 272px; height: 100%;}
.container .title-wrap .right-wrap .search-wrap .search  input { width: 100%; height: 100%; border: 1px solid #d9d9d9; border-radius: 2px;  font-size: 11px; padding: 5px 0 5px 8px;  }
.container .title-wrap .right-wrap .search-wrap .search  input::placeholder { font-size: 11px;  color: rgba(0, 0, 0, 0.25); }
.container .title-wrap .right-wrap .search-wrap .search i { position : absolute;  width: 14px;  top: 8px;  right: 12px;  margin: 0; color: rgba(0, 0, 0, 0.25); }
/* // */



/* 관리자 로그인페이지 */
.login-page .login-container { 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 100vw; 
    height: 100vh; 
    background-image: url('images/admin-login.png'); 
    background-position: center; 
    background-size: cover; 
}
.login-page .login-box { display: flex; flex-direction: column; align-items: center; justify-content: center; width: 420px;  height: 527px; padding: 0 85px; background-color: #fff; border-radius: 10px; box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.1); }
.login-page .login-box .login-header { width: 100%;  margin-bottom: 34.5px; display: flex; flex-direction: column; align-items: center;}
.login-page .login-box .login-header .logo-wrap { margin-bottom: 32px; width:139px; }
.login-page .login-box .login-header .sub {  display:block; width: 251px; text-align: center; font-size: 16px; font-weight: 300; letter-spacing: -0.4px; color: #a8a8a8; padding-bottom: 40px; border-bottom: 1px solid #c2c2c2;}
.login-page .login-box .login-input-wrap { display: flex; flex-direction: column; width: 100%; max-width: 260px; margin-bottom: 13px;} 
.login-page .login-box .login-input-wrap .input-box { display: flex; flex-direction: column; gap:8px; margin-bottom: 27px; }
.login-page .login-box .login-input-wrap .input-box .input-title {  font-size: 13px; font-weight: bold;  color: rgba(51, 51, 51, 0.87); }
.login-page .login-box .login-input-wrap .input-box input { height: 38px; padding-left: 10px; border-radius: 3px; border: solid 1px #dededf;  background-color: #fff;}
.login-page .login-box .login-input-wrap .input-box input::placeholder { font-size: 14px; font-weight: 500; color: #dededf; }
.login-page .login-box .login-input-wrap .input-box input:focus {border: solid 1px #6598d8;; box-shadow: 0 0 5px 0 rgba(0, 90, 200, 0.18); } /* outline 테두리 속성 수정 */
.login-page .login-box .login-btn { width: 100%; height: 48px;  background-color: #1890ff; color: #fff; font-weight: bold;   border-radius: 5px;}


/* 팝업관리 */
.popup-set {}
.popup-set .list-wrap { padding: 0 24px 0 24px; margin-bottom: 45px;}
.popup-set .list-wrap .popup-list { width: 100%;  display: flex; flex-wrap: wrap; gap: 25px; }
.popup-set .list-wrap .popup-list li { width: calc( (100% - 25px * 3) / 4); max-width: 272px; max-height: 309px;  border-radius: 4px; border: solid 1px #e9e9e9; background-color: #fff;}
.popup-set .list-wrap .popup-list li .popup-img { width: 100%; height: 150px; max-height: 150px; margin-bottom: 24px; }
.popup-set .list-wrap .popup-list li .popup-img  img { width: 100%;height: 100%; border-top-left-radius: 4px; border-top-right-radius: 4px;}
.popup-set .list-wrap .popup-list li .popup-contents { padding: 0 24px 24px 24px;  }
.popup-set .list-wrap .popup-list li .popup-contents .title { max-width: 222px; height: 20px;  min-height: 20px; font-size: 17px; margin-bottom: 8px; overflow: hidden; text-overflow: ellipsis; }
.popup-set .list-wrap .popup-list li .popup-contents .link {display: block; max-width: 222px; height: 18px;  min-height: 18px; font-size: 15px;   color: rgba(0, 0, 0, 0.45);margin-bottom: 24px; overflow: hidden; text-overflow: ellipsis; }
.popup-set .list-wrap .popup-list li .popup-contents .event-control { }
.popup-set .list-wrap .popup-list li .popup-contents .event-control a {  font-size: 14px;  color: #1890ff; position: relative; padding-right: 12px;}
.popup-set .list-wrap .popup-list li .popup-contents .event-control a::after { position: absolute; display: inline-block; content: "|"; top:0; right: 0;  transform: translateY(2px); color: #ccc;}
.popup-set .list-wrap .popup-list li .popup-contents .event-control .delete-btn {   font-size: 14px;  color: #1890ff; padding-left: 4px;}


/* 베너 등록 */
.member-detail { }
.member-detail .container { }
.member-detail .contents-wrap  { padding: 24px;  height: calc( 100vh - 156px);}  
.member-detail .contents-wrap .add-container { padding:32px 23px 32px 25px; border-radius: 2px; background-color: #fff;} 
.member-detail .contents-wrap .add-container .add-box { display: flex; gap:16px; width: 100%; align-items: center; padding-bottom: 24px;}
.member-detail .contents-wrap .add-container .add-box.headline-box .title {padding-bottom: 50px;}
.member-detail .contents-wrap .add-container .add-box .title {font-size: 14px;  color: rgba(0, 0, 0, 0.85); width: 76px; text-align: right; }
.member-detail .contents-wrap .add-container .add-box.f2 .title {  margin-left: -18px; width: 90px;}
.member-detail .contents-wrap .add-container .add-box .input-wrap { width: calc( 100% - 59px - 16px ); height: 40px;}
.member-detail .contents-wrap .add-container .add-box .input-wrap.headline {  height: 86px;}
.member-detail .contents-wrap .add-container .add-box .input-wrap.headline textarea { width: 100%; height: 86px; border: solid 1px #d9d9d9; border-radius: 2px; padding: 9px 12px; font-size: 14px; }
.member-detail .contents-wrap .add-container .add-box .input-wrap.headline textarea::placeholder { font-size: 14px; color: rgba(0, 0, 0, 0.25); line-height: 1.57;}
.member-detail .contents-wrap .add-container .add-box .input-wrap  input { width: 100%; height: 100%; border: solid 1px #d9d9d9; border-radius: 2px; padding: 11px 12px;}
.member-detail .contents-wrap .add-container .add-box .input-wrap  input::placeholder { font-size: 16px; color: rgba(0, 0, 0, 0.25); }
.member-detail .contents-wrap .add-container .add-box .file-find .file-upload  { display: flex; flex-direction: column;} 
.member-detail .contents-wrap .add-container .add-box .file-find .file-upload .btn-style-find  { width: 145px; height: 40px; display: flex;  gap:7.6px; align-items: center; justify-content: center; border-radius: 4px;  border: solid 1px #d9d9d9;    background-color: #fff; color: rgba(0, 0, 0, 0.65); cursor: pointer;} 
.member-detail .contents-wrap .add-container .add-box.f1 .file-find { position: relative; }
.member-detail .contents-wrap .add-container .add-box.f1 .file-find::after {content: "1280px*600px 최적화"; position: absolute; display: block; top: 12px; right: -150px; font-size: 14px; color: rgba(0, 0, 0, 0.25);; }
.member-detail .contents-wrap .add-container .add-box.f2 .file-find { position: relative; }
.member-detail .contents-wrap .add-container .add-box.f2 .file-find::after {content: "768px*472px 최적화"; position: absolute; display: block;  top: 12px; right: -150px; font-size: 14px;  color: rgba(0, 0, 0, 0.25); }
.member-detail .contents-wrap .add-container .add-box.upload {padding-bottom: 16px;}
.member-detail .contents-wrap .add-container .add-box .file-find .file-upload ul { display: flex; align-items: center; gap: 5px; padding: 0 8px 0 95px; } 
.member-detail .contents-wrap .add-container .add-box .file-find .file-upload li { width:125px; height: 22px;  border-radius: 11px; background-color: #f5f5f5; font-size: 12px; color: #787878;  display: flex; justify-content: space-evenly; align-items: center;} 
.member-detail .contents-wrap .add-container .add-box.list-box  {  width: calc( 100% - 59px - 16px );  margin-left: 91px; }
.member-detail .contents-wrap .add-container .add-box input[type="file"] {  position: absolute; width: 0; height: 0; padding: 0; overflow: hidden; border: 0; }
.member-detail .contents-wrap .add-container .add-box.list-box .asfile_ul { width: 100%;  display: flex; flex-direction: column; gap: 10px; }
.member-detail .contents-wrap .add-container .add-box.list-box .asfile_ul li { display: flex; justify-content: center; align-items: center; max-width: 435px; height: 38px;  gap:12.5px; border-radius: 2px;  border: solid 1px #d9d9d9; background-color: #fff;}
.member-detail .contents-wrap .add-container .add-box.list-box .asfile_ul li > img { width: 48px;}
.member-detail .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name { display: flex; align-items: center;  width: calc( 100% - 48px - 10.5px); justify-content: space-between;}
.member-detail .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name .xi-close-thin { font-size: 16px; font-weight: bold; color: rgba(0, 0, 0, 0.45); cursor: pointer;}
.member-detail .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name  span { font-size: 14px;  color: #1890ff;}
.member-detail .contents-wrap .add-container .btn-wrap {display: flex; width: 100%; gap:16px;}
.member-detail .contents-wrap .add-container .btn-wrap button {font-size: 16px; font-weight: normal; }
.member-detail .contents-wrap .add-container .btn-wrap .cancle-btn { width: 104px; height: 40px; margin-left: 86px; border-radius: 2px;  border: solid 1px #d9d9d9; background-color: #fff; color: rgba(0, 0, 0, 0.6);}
.member-detail .contents-wrap .add-container .btn-wrap .save-btn { width: 104px; height: 40px;  border-radius: 2px;  background-color: #1890ff; color: #fff;}

/* 베너관리 */
.banner-set {}

.banner-set .list-wrap { 
    padding: 0 24px 0 24px; 
    margin-bottom: 45px;
    padding-top: 80px;
    overflow-x: auto;
    min-height: 720px;
}
.banner-set .list-wrap .popup-list { 
    display: flex; 
    flex-wrap: wrap; 
    width: 100%;  
    min-width: calc(250px * 5 + 25px * 4);
    gap: 25px;
    margin-bottom: 20px;
}

.banner-set .list-wrap .popup-list li { 
    width: calc( (100% - 35px * 3) / 5);
    min-width: 250px;
    max-width: 300px;
    max-height: 309px;
    border-radius: 4px;
    border: solid 1px #e9e9e9;
    background-color: #fff;
}
.banner-set .list-wrap .popup-list li .popup-img { width: 100%; max-height: 150px;  }
.banner-set .list-wrap .popup-list li .popup-img  img { width: 100%; border-top-left-radius: 4px; border-top-right-radius: 4px;}
.banner-set .list-wrap .popup-list li .popup-contents { padding: 24px;  }
.banner-set .list-wrap .popup-list li .popup-contents .title { max-width: 222px; height: 20px;  min-height: 20px; font-size: 17px; margin-bottom: 16px; overflow: hidden; text-overflow: ellipsis; }
.banner-set .list-wrap .popup-list li .popup-contents .link {display: block; max-width: 222px; height: 18px;  min-height: 18px; font-size: 15px;   color: rgba(0, 0, 0, 0.45); margin-bottom: 16px; overflow: hidden; text-overflow: ellipsis; }
.banner-set .list-wrap .popup-list li .popup-contents .event-control { }
.banner-set .list-wrap .popup-list li .popup-contents .event-control a {  font-size: 14px;  color: #1890ff; position: relative; padding-right: 12px;}
.banner-set .list-wrap .popup-list li .popup-contents .event-control a::after { position: absolute; display: inline-block; content: "|"; top:0; right: 0;  transform: translateY(2px); color: #ccc;}
.banner-set .list-wrap .popup-list li .popup-contents .event-control .delete-btn {   font-size: 14px;  color: #1890ff; padding-left: 4px;}

.banner-add { }
.banner-add .container { }
.banner-add .contents-wrap {
    padding: 24px;
    height: calc( 100vh - 156px);
    padding-top: 100px;
}  
.banner-add .contents-wrap .add-container { 
    padding:32px 32px 32px 30px; border-radius: 2px; background-color: #fff;
    min-width: 500px;
} 
.banner-add .contents-wrap .add-container .add-box { position: relative; display: flex; gap:16px; width: 100%; align-items: center; padding-bottom: 24px;}
.banner-add .contents-wrap .add-container .add-box.f1::after { position: absolute; content: "1280px*600px 최적화"; display: block; font-size: 14px;  color: rgba(0, 0, 0, 0.25); font-weight: normal; left: 250px;}
.banner-add .contents-wrap .add-container .add-box.f2::after { position: absolute; content: "768px*472px 최적화"; display: block; font-size: 14px;  color: rgba(0, 0, 0, 0.25); font-weight: normal; left: 250px;}
.banner-add .contents-wrap .add-container .add-box.headline-box .title {padding-bottom: 50px;}
.banner-add .contents-wrap .add-container .add-box .title {font-size: 14px;  color: rgba(0, 0, 0, 0.85); width: 93px; text-align: right;  margin-left: -20px;}
.banner-add .contents-wrap .add-container .add-box .input-wrap { width: calc( 100% - 59px - 16px ); height: 40px;}
.banner-add .contents-wrap .add-container .add-box .input-wrap.headline {  height: 86px;}
.banner-add .contents-wrap .add-container .add-box .input-wrap.headline textarea { width: 100%; height: 86px; border: solid 1px #d9d9d9; border-radius: 2px; padding: 9px 12px; font-size: 14px; }
.banner-add .contents-wrap .add-container .add-box .input-wrap.headline textarea::placeholder { font-size: 14px; color: rgba(0, 0, 0, 0.25); line-height: 1.57;}

.banner-add .contents-wrap .add-container .add-box .input-wrap  input { width: 100%; height: 100%; border: solid 1px #d9d9d9; border-radius: 2px; padding: 11px 12px;}
.banner-add  .contents-wrap .add-container .add-box .input-wrap  input::placeholder { font-size: 16px; color: rgba(0, 0, 0, 0.25); }
.banner-add  .contents-wrap .add-container .add-box .file-find .file-upload  { display: flex; flex-direction: column;} 
.banner-add  .contents-wrap .add-container .add-box .file-find .file-upload .btn-style-find  { width: 145px; height: 40px; display: flex;  gap:7.6px; align-items: center; justify-content: center; border-radius: 4px;  border: solid 1px #d9d9d9;    background-color: #fff; color: rgba(0, 0, 0, 0.65); cursor: pointer;} 

.banner-add .contents-wrap .add-container .add-box.upload {padding-bottom: 16px;}
.banner-add  .contents-wrap .add-container .add-box .file-find .file-upload ul { display: flex; align-items: center; gap: 5px; padding: 0 8px 0 95px; } 
.banner-add  .contents-wrap .add-container .add-box .file-find .file-upload li { width:125px; height: 22px;  border-radius: 11px; background-color: #f5f5f5; font-size: 12px; color: #787878;  display: flex; justify-content: space-evenly; align-items: center;} 
.banner-add .contents-wrap .add-container .add-box.list-box  {  width: calc( 100% - 59px - 16px );  margin-left: 91px; }
.banner-add  .contents-wrap .add-container .add-box input[type="file"] {  position: absolute; width: 0; height: 0; padding: 0; overflow: hidden; border: 0; }
.banner-add .contents-wrap .add-container .add-box.list-box .asfile_ul { width: 100%;  display: flex; flex-direction: column; gap: 10px; }

.banner-add .contents-wrap .add-container .add-box.list-box .asfile_ul li { display: flex; justify-content: center; align-items: center; max-width: 435px; height: 38px;  gap:12.5px; border-radius: 2px;  border: solid 1px #d9d9d9; background-color: #fff;}
.banner-add .contents-wrap .add-container .add-box.list-box .asfile_ul li > img { width: 48px;}
.banner-add  .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name { display: flex; align-items: center;  width: calc( 100% - 48px - 10.5px); justify-content: space-between;}
.banner-add  .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name .xi-close-thin { font-size: 16px; font-weight: bold; color: rgba(0, 0, 0, 0.45); cursor: pointer;}
.banner-add .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name  span { font-size: 14px;  color: #1890ff;}
.banner-add   .contents-wrap .add-container .btn-wrap {display: flex; width: 100%; gap:16px;}
.banner-add   .contents-wrap .add-container .btn-wrap button {font-size: 16px; font-weight: normal; }
.banner-add  .contents-wrap .add-container .btn-wrap .cancle-btn { width: 104px; height: 40px; margin-left: 86px; border-radius: 2px;  border: solid 1px #d9d9d9; background-color: #fff; color: rgba(0, 0, 0, 0.6);}
.banner-add  .contents-wrap .add-container .btn-wrap .save-btn { width: 104px; height: 40px;  border-radius: 2px;  background-color: #1890ff; color: #fff;}

/* 회원 관리 */
.member-set {}
.member-set .contents-wrap  { 
    padding: 24px;
    padding-top: 100px;  
} 
.member-set .contents-wrap .table-container { padding:33px 32px 32px 30px; border-radius: 2px; background-color: #fff; margin-bottom: 25px; } 
.member-set .contents-wrap .table-container .table-wrap { margin-bottom: 26px; }
.member-set .contents-wrap .table-container .table-wrap table { width: 100%; }
.member-set .contents-wrap .table-container .table-wrap table thead{ height: 54px; text-align: left;  border-radius: 4px;  background-color: #fafafa; border-bottom: 1px solid #e8e8e8; }
.member-set .contents-wrap .table-container .table-wrap table thead tr th { 
    font-size: 14px;  font-weight: normal; color: rgba(0, 0, 0, 0.85);
}
.member-set .contents-wrap .table-container .table-wrap table tbody tr td { font-size: 14px;  font-weight: normal;  color: rgba(0, 0, 0, 0.65);}
.member-set .contents-wrap .table-container .table-wrap table thead tr th:nth-child(3) { padding-left: 10px; }
.member-set .contents-wrap .table-container .table-wrap table tbody tr td:nth-child(3) { padding-left: 10px; }
.member-set .contents-wrap .table-container .table-wrap table thead tr th:first-child { padding-left: 16px; }
.member-set .contents-wrap .table-container .table-wrap table tbody tr td:first-child { padding-left: 16px;  }
.member-set .contents-wrap .table-container .table-wrap table tbody tr td:nth-child(2){ max-width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.member-set .contents-wrap .table-container .table-wrap table tbody tr { 
    height: 50px; 
    text-align: left; 
    /* border-bottom: 1px solid #e8e8e8;  */
}
.member-set .contents-wrap .table-container .table-wrap table tbody tr a {font-size: 14px;  color: #1890ff; position: relative; padding-right: 12px; }
.member-set .contents-wrap .table-container .table-wrap table tbody tr a::after { position: absolute; display: inline-block; content: "|"; top:0; right: 0; width: 4px;  transform: translateY(2px); color: #ccc;}
.member-set .contents-wrap .table-container .table-wrap table tbody .btn-del { padding-left: 4px; font-size: 14px; color: #1890ff; }

/* 기업회원 관리 */
.member-set.company  .contents-wrap .table-container .table-wrap table .status-td { display: flex; align-items: center; line-height: 50px;  }
.member-set.company  .contents-wrap .table-container .table-wrap table .cir { width: 8px; height: 8px; border-radius: 50%; margin-right:8px;  }
.member-set.company  .contents-wrap .table-container .table-wrap table .cir.red {background-color: #ec323e; }
.member-set.company  .contents-wrap .table-container .table-wrap table .cir.blue {background-color: #469ad5; }

/* 탈퇴회원 관리 */
.member-set.withdrawal .contents-wrap .table-container .table-wrap table tbody tr a::after { display: none; }
.member-set.withdrawal .contents-wrap .table-container .table-wrap table tbody .btn-del { padding-left: 0px; }

/* 신고된 상품 관리 */
.list-set {}
.list-set .contents-wrap  { 
    padding: 24px;  
    padding-top: 100px;
} 
.list-set .contents-wrap .table-container { 
    padding:33px 32px 32px 30px; border-radius: 2px; background-color: #fff; margin-bottom: 25px; 
    min-width: 1025px;
} 
.list-set .contents-wrap .table-container .table-wrap { margin-bottom: 26px; }
.list-set .contents-wrap .table-container .table-wrap table { width: 100%; }
.list-set .contents-wrap .table-container .table-wrap table thead{ height: 54px; text-align: left;  border-radius: 4px;  background-color: #fafafa; border-bottom: 1px solid #e8e8e8; }
.list-set .contents-wrap .table-container .table-wrap table thead tr th { font-size: 14px;  font-weight: normal; color: rgba(0, 0, 0, 0.85);}
.list-set .contents-wrap .table-container .table-wrap table tbody tr td { font-size: 14px;  font-weight: normal;  color: rgba(0, 0, 0, 0.65);}
.list-set .contents-wrap .table-container .table-wrap table thead tr th:nth-child(3) { padding-left: 10px; }
.list-set .contents-wrap .table-container .table-wrap table tbody tr td:nth-child(3) { padding-left: 10px; }
.list-set .contents-wrap .table-container .table-wrap table thead tr th:first-child { padding-left: 16px; }
.list-set .contents-wrap .table-container .table-wrap table tbody tr td:first-child { padding-left: 16px; }
.list-set .contents-wrap .table-container .table-wrap table tbody tr td:first-child a {display: block; max-width: 230px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.list-set .contents-wrap .table-container .table-wrap table tbody tr td:nth-child(2){ max-width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.list-set .contents-wrap .table-container .table-wrap table tbody tr td:nth-child(3){ max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.list-set  .contents-wrap .table-container .table-wrap table tbody tr { 
    height: 50px; text-align: left; border-bottom: 1px solid #e8e8e8; 
}

.list-set  .contents-wrap .table-container .table-wrap table tbody tr:hover {
    background-color: #e8e8e8;
}

.list-set .contents-wrap .table-container .table-wrap table tbody .btn-del { padding-left: 4px; font-size: 14px; color: #1890ff; }
/* .villa-set .list-wrap .popup-list li .popup-contents .event-control a {  font-size: 14px;  color: #1890ff; position: relative; padding-right: 12px; }
.villa-set .list-wrap .popup-list li .popup-contents .event-control a::after { position: absolute; display: inline-block; content: "|"; top:0; right: 0;  transform: translateY(2px); color: #ccc; } */
/* 1:1문의관리 */
.list-set.inquire-set .contents-wrap .table-container .table-wrap table tbody .btn-del { padding-left: 0px; font-size: 14px; color: #1890ff; }
.list-set.inquire-set .contents-wrap .table-container .table-wrap table tbody tr td:nth-child(5){ max-width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

/* 공지사항 관리*/
.list-set.notice-set .contents-wrap .table-container .table-wrap table tbody .set-td a { position: relative; padding-right: 12px;}
.list-set.notice-set .contents-wrap .table-container .table-wrap table tbody .set-td a::after { position: absolute; display: inline-block; content: "|"; top:0; right: 0px;  transform: translateY(2px); color: #ccc; }

/* 신고된 상품 관리 */
.table-wrap table tbody .set-td { min-width: 88px ;}
.list-set.report .contents-wrap .table-container .table-wrap table tbody .set-td .btn-del { position: relative; padding-right: 12px;}
.list-set.report .contents-wrap .table-container .table-wrap table tbody .set-td .btn-del.left::after { position: absolute; display: inline-block; content: "|"; top:0; right: 0px;  transform: translateY(2px); color: #ccc; }


/* 개인 회원 관리 상세 */


/* 회원 관리 */
.member-detail { }
.member-detail .container { }
.member-detail .contents-wrap  { 
    padding: 24px; width: 100%; height: calc( 100vh - 156px);
    min-width: 1200px;
    padding-top: 100px;
}  

.member-detail .contents-wrap .user-container { width: 100%; height: 136px; margin-bottom: 41px;}
.member-detail .contents-wrap .user-container .img-wrap { width: 136px; height: 100%;  }
.member-detail .contents-wrap .user-container .img-wrap img { width: 100%; height: 100%; border-radius: 50%; object-fit: cover;}
.member-detail .contents-wrap .add-container { 
    width: 100%; padding:32px 64px 32px 58px; border-radius: 2px; background-color: #fff;
    min-width: 336px;
} 
.member-detail .contents-wrap .add-container .input-container { display: flex; gap: 107px;}
.member-detail .contents-wrap .add-container .input-container .list-set{ width: 50%;}
.member-detail .contents-wrap .add-container ul li { display: flex; align-items: center; gap: 16px; margin-bottom: 23px;}
.member-detail .contents-wrap .add-container ul li.reposition { align-items: start; }
.member-detail .contents-wrap .add-container ul li.reposition .address-title { padding-top: 8px;}
.member-detail .contents-wrap .add-container ul li .form-title { min-width: 55px; font-size: 16px; font-weight: bold; color: #333333; text-align: right; }

.member-detail .contents-wrap .add-container ul li .input-wrap {width: 400px;  height: 40px;  }
.member-detail .contents-wrap .add-container ul li .input-wrap input {width: 100%; height:100%; background-color: #f5f5f5; border-radius: 2px; border: solid 1px #d8d8d8; padding:0px 16px; color: rgba(0, 0, 0, 0.65);  font-weight: normal;} 
.member-detail .contents-wrap .add-container ul li .input-wrap.input-resize { width: 184px;}
input[type="date"]{ position: relative;  }
input[type="date"]::-webkit-calendar-picker-indicator { opacity: 1;  display: block;  position: absolute;  background: url('images/icon-calendar.png') no-repeat;  background-size: contain;  right: 16px;  width: 20px;  height: 20px; }

.member-detail .contents-wrap .add-container ul li .address-wrap .input-wrap { margin-bottom: 8px; }

.member-detail .contents-wrap .add-container .right-box { width: 40% }
.member-detail .contents-wrap .add-container .left-box { width: calc(100% - 40% - 107px);}
.member-detail .contents-wrap .add-container ul li .input-wrap.check-box {position: relative; height: 24px; width: 24px; border: none;}
input[type="checkbox"]:checked {  background: #1890ff; }
.member-detail .contents-wrap .add-container ul li .input-wrap.check-box input { border-radius: 2px; border: solid 1px #d8d8d8; }
.member-detail .contents-wrap .add-container ul li .form-title { min-width: 68px; }
.member-detail .contents-wrap .add-container ul li .agree-text { font-size: 16px; color: #a4a4a4; }

.member-detail .contents-wrap .add-container .add-box { display: flex; gap:16px; width: 100%; align-items: center; padding-bottom: 24px;}
.member-detail .contents-wrap .add-container .add-box.headline-box .title {padding-bottom: 50px;}
.member-detail .contents-wrap .add-container .add-box .title {font-size: 14px;  color: rgba(0, 0, 0, 0.85); width: 76px; text-align: right; }

.member-detail .contents-wrap .add-container .btn-wrap {display: flex; width: 100%; gap:16px;}
.member-detail .contents-wrap .add-container .btn-wrap button {font-size: 16px; font-weight: normal; }
.member-detail .contents-wrap .add-container .btn-wrap .save-btn { width: 104px; height: 40px;  margin-left: 82px; border-radius: 2px;  background-color: #1890ff; color: #fff;}

/* 회원 관리 기업 */
.member-detail.company .user-container { display: flex; justify-content: space-between;}
.member-detail.company .user-container .add-box.status-text { position: relative}
.member-detail.company .user-container .add-box.status-text::after { position: absolute; display: block; content: "상태";font-weight: bold; left: -39px; }

.member-detail.company .contents-wrap .add-container .input-container {  gap: 88px;}
.member-detail.company .contents-wrap .add-container .left-box { width: calc(100% - 40% - 88px);}
.member-detail.company .contents-wrap .add-container .add-box .title { width: 55px;}
.member-detail.company .contents-wrap .add-container ul li .form-title { min-width: 97px; }
.member-detail.company .contents-wrap .add-container ul li.resize { margin-bottom: 68px;}
.member-detail.company .contents-wrap .add-container .btn-wrap .save-btn {  margin-left: 113px; }
.member-detail.company .contents-wrap .add-container ul li .file_name {display: flex; align-items: center; justify-content: space-between; width: 400px; height:40px;  background-color: #fff; border-radius: 2px; border: solid 1px #d8d8d8; padding:0px 16px; color: rgba(0, 0, 0, 0.65);  font-weight: normal;} 
.member-detail.company .contents-wrap .add-container ul li .file_name .right-info {display: flex; justify-content: center; align-items: center;}
.member-detail.company .contents-wrap .add-container ul li .file_name .name { font-size: 16px;  color: rgba(0, 0, 0, 0.65); }
.member-detail.company .contents-wrap .add-container ul li .file_name button { font-size: 16px; color: rgba(0, 0, 0, 0.65); }
.member-detail.company .contents-wrap .add-container ul li .file_name i { color: rgba(0, 0, 0, 0.65);  }

/* 토글 스위치 */
.toggleSwitch { width: 52px; height: 23px; display: block; position: relative; border-radius: 30px; background-color: rgba(0, 0, 0, 0.65); cursor: pointer; }
.toggleSwitch .toggleButton { width: 18px; height: 18px; position: absolute; top: 50%; left: 4px; transform: translateY(-50%); border-radius: 50%; background-color: #fff; }
#toggle:checked ~ .toggleSwitch { background-color: #1890ff; }
#toggle:checked ~ .toggleSwitch .toggleButton { left: calc(100% - 22px); background: #fff; }
#toggle1:checked ~ .toggleSwitch { background-color: #1890ff; }
#toggle1:checked ~ .toggleSwitch .toggleButton { left: calc(100% - 22px); background: #fff; }
.toggleSwitch, .toggleButton { transition: all 0.2s ease-in; }
       

/* 꼬모톡 내역 */
.talk-set  .contents-wrap { 
    display: flex; gap: 16px; padding: 20px 24px;
    padding-top: 100px;
}
.talk-set  .contents-wrap .left-container { padding:10px 16px 29px; width: 23.5%; width:300px; height:777px; border-radius: 2px; background-color: #fff;} 
.talk-set .left-container .talk-list li:hover{ background-color: #ebebeb;}
.talk-set .left-container .talk-list li { position: relative;  display: flex; align-items: center;  padding: 24px 0; gap:16px; border-bottom: 1px solid rgba(0, 0, 0, 0.1); cursor: pointer;}
.talk-set .left-container .talk-list li.current { background-color: #ebebeb; }
.talk-set .left-container .talk-list li .img-wrap { width: 56px; height: auto; }
.talk-set .left-container .talk-list li .img-wrap img { width: 100%; height: 100%;}
.talk-set .left-container .talk-list li .title-wrap {  padding: 0px;  display: flex; justify-content: unset; gap: 8px; align-items: center; background-color: unset; }
.talk-set .left-container .talk-list li .title-wrap .user-name { width:65px;  letter-spacing: normal; font-size: 16px;  font-weight: bold;  color: rgba(0, 0, 0, 0.65); white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.talk-set .left-container .talk-list li .title-wrap i { font-size: 16px; color: rgba(0, 0, 0, 0.35);}
.talk-set .left-container .talk-list li .text-wrap p { font-size: 14px; font-weight: normal; color: rgba(0, 0, 0, 0.35); width: 188px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.talk-set .left-container .talk-list li .date-wrap { 
    position: absolute;  
    top: 10px; 
    /* top:35px;  */
    right: 0;  
    min-width: 49px;
}
.talk-set .left-container .talk-list li .date-wrap .chat-date { font-size: 12px; font-weight: normal;  color: rgba(0, 0, 0, 0.1);}


.talk-set  .contents-wrap .tab-content  { display: none; }
.talk-set  .contents-wrap .tab-content.current { display: block; width: calc( 100% - 16px - 23.5%); border-radius: 2px; background-color: #ebebeb;} 
.talk-set  .contents-wrap .tab-content .top-wrap { width: 100%; height: 104px; padding: 24px; background-color: #fff; display: flex; align-items: center;}
.talk-set  .contents-wrap .tab-content .top-wrap .img-wrap {  width: 56px;}
.talk-set  .contents-wrap .tab-content .top-wrap .img-wrap img { width: 100%;}
.talk-set  .contents-wrap .tab-content .top-wrap .title-wrap { justify-content: flex-start; gap: 8px;}
.talk-set  .contents-wrap .tab-content .top-wrap .title-wrap p  { font-size: 14px; font-weight: normal; color:rgba(0, 0, 0, 0.65); font-weight: bold;}
.talk-set  .contents-wrap .tab-content .top-wrap .title-wrap i { font-size: 16px; color: rgba(0, 0, 0, 0.35);}

.talk-set  .contents-wrap .tab-content .chat-wrap { width: 100%; height: calc( 100% - 104px); max-height: 673px; overflow-y: scroll;  padding: 24px;  background-color: #ebebeb;} 
.talk-set  .contents-wrap .tab-content .chat-wrap ul li { display: flex; gap:8px; height: 100%;}
.talk-set  .contents-wrap .tab-content .chat-wrap .img-wrap { width: 56px; height: 56px;}
.talk-set  .contents-wrap .tab-content .chat-wrap .img-wrap img { width: 100%;}

.chat-wrap li { display: flex;  align-items: flex-start; }
.chat-wrap .textbox { position: relative; display: inline-block; width: 240px; padding: 10px; margin-top: 7px; border-radius: 10px; font-size: 16px;  font-weight: normal;  background-color: #fff;  line-height: 1.5; letter-spacing: -0.4px;  box-shadow: 0 5px 10px rgba(0,0,0,0.2), 0 1px 1px rgba(151, 151, 151, 0.23);  z-index: 2; }
.chat-wrap .textbox::before { position: absolute; display: block;  top: 0; font-size: 1.5rem; }
.chat-wrap .user1 .textbox { margin-left: 20px; }
.chat-wrap .user1 .textbox::before { left: -15px; content: "◀";  color: #fff; }
.chat-wrap .user2 .textbox::before { right: -15px;  content: "▶";  color: #fff; }
.chat-wrap .user2 {  flex-direction: row-reverse; }
.chat-wrap .user2 .textbox { margin-right: 20px; }
.chat-wrap .date { height: 100%; align-self: flex-end;}
.chat-wrap .date .chat-date {  font-size: 12px; font-weight: normal;  letter-spacing: -0.3px;  color: #8d8882;}

/* 공지사항관리 - 공지사항 등록 */
.notice-add { }
.notice-add .container { }
.notice-add .contents-wrap  { 
    padding: 24px; 
    padding-top: 100px;
} 
.notice-add .contents-wrap .add-container { 
    min-width: 720px;
    width: 100%; padding:32px 32px 32px 30px; border-radius: 2px; background-color: #fff;
} 
.notice-add .contents-wrap .add-container .add-box { display: flex; gap:16px; width: 100%; align-items: center; padding-bottom: 24px;}
.notice-add .contents-wrap .add-container .add-box .title {font-size: 16px;  color: rgba(0, 0, 0, 0.85); width: 73px; text-align: right; }
.notice-add .contents-wrap .add-container .add-box .input-wrap { width: calc( 100% - 59px - 16px ); height: 40px;}
.notice-add .contents-wrap .add-container .add-box .input-wrap.checkbox-wrap { width: 20px}
.notice-add .contents-wrap .add-container .add-box .input-wrap  input { width: 100%; height: 100%; border: solid 1px #d9d9d9; border-radius: 2px; padding: 11px 12px;}
.notice-add .contents-wrap .add-container .add-box .input-wrap  input::placeholder { font-size: 16px; color: rgba(0, 0, 0, 0.25); }
.notice-add .contents-wrap .add-container .btn-wrap {display: flex; width: 100%; gap:16px;}
.notice-add .contents-wrap .add-container .btn-wrap button {font-size: 16px; font-weight: normal; }
.notice-add .contents-wrap .add-container .btn-wrap .cancle-btn { width: 104px; height: 40px; margin-left: 86px; border-radius: 2px;  border: solid 1px #d9d9d9; background-color: #fff; color: rgba(0, 0, 0, 0.6);}
.notice-add .contents-wrap .add-container .btn-wrap .save-btn { width: 104px; height: 40px;  border-radius: 2px;  background-color: #1890ff; color: #fff;}
.notice-add .contents-wrap .add-container .add-box.editor-box form { width: 100%;}
.notice-add .contents-wrap .add-container .add-box.upload {padding-bottom: 16px;}
.notice-add .contents-wrap .add-container .add-box .file-find { cursor: pointer;}
.notice-add .contents-wrap .add-container .add-box .file-find .file-upload { display: flex; justify-content: center; align-items: center; width: 145px; height: 40px; background-color: #fff;   border-radius: 4px;  border: solid 1px #d9d9d9; }
.notice-add .contents-wrap .add-container .add-box .file-find .file-upload .btn-style-find { cursor: pointer;}
.notice-add .contents-wrap .add-container .add-box.list-box  { display: flex; align-items: flex-start; width: calc( 100% - 91px); height: 154px;  background-color: #f8f8f8;; padding: 24px; margin-left: 91px; margin-bottom: 31px;}
.notice-add .contents-wrap .add-container .add-box.list-box .file_name .img-wrap { width: 16px; height: 16px; margin-right: 4px;  transform: rotate(180deg);}
.notice-add .contents-wrap .add-container .add-box.list-box .file_name .img-wrap  img { width: 100%; }
.notice-add .contents-wrap .add-container .add-box input[type="file"] {  position: absolute; width: 0; height: 0; padding: 0; overflow: hidden; border: 0; }
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul { width: 100%;  display: flex; flex-direction: column;}
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul li { display: flex; align-items: center; max-width: 100%; height: 20px;  gap:12.5px; border-radius: 2px; padding: 15px 0;}
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul li:hover { background-color: #f0eeee;}
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul li > img { width: 48px;}
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name { display: flex; align-items: center;  width: calc( 100% - 48px - 10.5px); }
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name .xi-close-thin { display: block; font-size: 15px; font-weight: bold; color: rgba(0, 0, 0, 0.45); margin-left: 5px; cursor: pointer;}
.notice-add .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name  span { font-size: 14px;  color: rgba(0, 0, 0, 0.65);  font-weight: normal;}

/* 1:1문의관리 상세 */
.inquire-detail { }
.inquire-detail .container { }
.inquire-detail .contents-wrap { 
    padding: 24px; 
    padding-top: 100px;
} 

.inquire-detail .contents-wrap .add-container {width: 100%; padding:32px 32px 32px 30px; border-radius: 2px; background-color: #fff;} 
/* 상단 */
.inquire-detail .contents-wrap .add-container .user-info-wrap { 
    display: flex; 
    flex-direction: column;
    width: 80%; 
}
.inquire-detail .contents-wrap .add-container .user-info-wrap .user-info { font-size: 16px; font-weight: bold; color: rgba(0, 0, 0, 0.85);}
/* //상단 */
.inquire-detail .contents-wrap .add-container .add-box { display: flex; gap:16px; width: 100%; align-items: center; padding-bottom: 24px;}
.inquire-detail .contents-wrap .add-container .add-box .title {font-size: 16px;  color: rgba(0, 0, 0, 0.85); width: 73px; text-align: right; }
.inquire-detail .contents-wrap .add-container .add-box .input-wrap.show { width: 100%; height: 529px;  border: solid 1px #d9d9d9;  border-radius: 2px; padding: 11px 12px;}
.inquire-detail .contents-wrap .add-container .add-box .input-wrap { width: calc( 100% - 59px - 16px ); height: 40px;}
.inquire-detail .contents-wrap .add-container .add-box .input-wrap.checkbox-wrap { width: 20px}
.inquire-detail .contents-wrap .add-container .add-box .input-wrap  input { width: 100%; height: 100%; border: solid 1px #d9d9d9; border-radius: 2px; padding: 11px 12px;}
.inquire-detail .contents-wrap .add-container .add-box .input-wrap  input::placeholder { font-size: 16px; color: rgba(0, 0, 0, 0.25); }
.inquire-detail .contents-wrap .add-container .add-box.add-box.editor-box { align-items: flex-start;}
.inquire-detail .contents-wrap .add-container .add-box.add-box.editor-box .input-wrap { height: 529px;}
.inquire-detail .contents-wrap .add-container .btn-wrap {display: flex; width: 100%; gap:16px; margin-left: 91px;}
.inquire-detail .contents-wrap .add-container .btn-wrap button {font-size: 16px; font-weight: normal; }
.inquire-detail .contents-wrap .add-container .btn-wrap .cancle-btn { width: 104px; height: 40px; margin-left: 86px; border-radius: 2px;  border: solid 1px #d9d9d9; background-color: #fff; color: rgba(0, 0, 0, 0.6);}
.inquire-detail .contents-wrap .add-container .btn-wrap .save-btn { width: 104px; height: 40px;  border-radius: 2px;  background-color: #1890ff; color: #fff;}


.inquire-detail .contents-wrap .add-container .add-box.download-box { align-items: flex-start;}
.inquire-detail .contents-wrap .add-container .add-box.download-box  .file_name { display: flex;  width: 100%; justify-content: space-between; }
.inquire-detail .contents-wrap .add-container .add-box.download-box  .file_name .left-info { display: flex; min-width: 200px; margin-left: 18px;}
.inquire-detail .contents-wrap .add-container .add-box.download-box  .file_name .left-info .img-wrap { width: 16px; height: 16px; margin-right: 4px; transform: rotate(180deg);}
.inquire-detail .contents-wrap .add-container .add-box.download-box  .file_name .left-info .img-wrap  img { width: 100%; }
.inquire-detail .contents-wrap .add-container .add-box.download-box  .file_name .right-info {width: 100px; margin-right: 10px;}
.inquire-detail .contents-wrap .add-container .add-box.download-box  .file_name .right-info i {margin-left: 10px;}

.inquire-detail .contents-wrap .add-container .add-box .asfile_ul { width: 100%;  display: flex; flex-direction: column; gap: 10px; align-items: flex-start; width: calc( 100% - 91px); height: 154px;  background-color: #f8f8f8;; padding: 24px 0px; margin-bottom: 7px;}
.inquire-detail .contents-wrap .add-container .add-box .asfile_ul li { display: flex; align-items: center; max-width: 100%; width: 100%; height: 20px; gap:12.5px;  border-radius: 2px; }
.inquire-detail .contents-wrap .add-container .add-box .asfile_ul li:hover {height: 20px;  background-color: #f0eeee;}
.inquire-detail .contents-wrap .add-container .add-box .asfile_ul li > img { width: 48px;}
.inquire-detail .contents-wrap .add-container .add-box .asfile_ul li .file_name { display: flex; align-items: center;  }
.inquire-detail .contents-wrap .add-container .add-box .asfile_ul li .file_name .xi-close-thin { display: block; font-size: 15px; font-weight: bold; color: rgba(0, 0, 0, 0.45); margin-left: 5px; cursor: pointer;}
.inquire-detail .contents-wrap .add-container .add-box.list-box .asfile_ul li .file_name  span { font-size: 14px;  color: rgba(0, 0, 0, 0.65);  font-weight: normal;}

.popup-container { display: none; z-index: 9999; position: fixed; left: 0; top: 0; width: 100%; height: 100%;  -webkit-backdrop-filter: blur(4px); backdrop-filter: blur(4px); background-color: #00000066; justify-content: center; align-items: center; }
.popup-container.open { display: flex; }
.popup-container .content-box {  position: relative; display: flex; flex-direction: column;  align-items: center; justify-content: center; width: 620px;  padding: 32px 90px 64px; border-radius: 16px;  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);  background-color: #fff; }
.popup-container .content-box h2 { width: 100%;  font-size: 26px; font-weight: bold;  margin-bottom: 40px; padding-bottom: 40px;  border-bottom: 1px solid rgba(0, 0, 0, 0.65); text-align: center; }
.popup-container .content-box  .content {text-align: center; font-size: 16px; font-weight: normal; color: rgba(0, 0, 0, 0.65); line-height: 1.5; margin-bottom: 40px;}
.popup-container .content-box  .content .content-mark { font-weight: bold; } 
.popup-container .content-box  .reason-box  {display: flex; justify-content: center; align-items: center; width: 100%; min-height: 54px; border-radius: 4px; background-color: #f5f5f5; margin-bottom: 40px;  padding: 17.5px;} 
.popup-container .content-box  .reason-box .title { font-size: 16px; font-weight: normal; } 
.popup-container .del-btn { color: #fff; font-size: 16px; font-weight: bold;  width: 184px; height: 48px; border-radius: 4px;  background-color: #1890ff; }
.popup-container .content-box  .close-btn { position: absolute;  top: 24px; right: 28px; width: 48px;  height: 48px; }
.popup-container .content-box  .close-btn i { font-size: 32px; color: rgba(0, 0, 0, 0.35); }