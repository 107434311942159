@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Thin.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraLight.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Light.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Regular.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Medium.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-SemiBold.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Bold.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-ExtraBold.ttf') format("truetype");
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot');
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.eot?#iefix') format('embedded-opentype'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff2') format('woff2'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.woff') format('woff'),
      url('https://cdn.jsdelivr.net/gh/webfontworld/pretendard/Pretendard-Black.ttf') format("truetype");
  font-display: swap;
}
/*===========================================================================
CSS 초기화
============================================================================*/
* { 
  font-family: 'Pretendard', 'Open Sans' ,sans-serif;  
  box-sizing:border-box; 
  margin:0; 
  padding:0; 
  /* font-weight: 300; */
}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, em, img, strong, b, i, dl, dt, dd, ol, ul, li, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, footer, header, menu, nav, section, video, a {border:0; outline:0; font-size:100%; color:#333; line-height:1;}
body {line-height:1;}

html, body {
  /* width: 100vw;
  height: 100vh; */
  background-color: #f5f5f5;
}

article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {display:block;}
ul, ol, li {
  list-style:none;
}

#root > div > div > ul > li.select > a > div {
  background-color: #0e99ff; opacity: 1;
}

li:hover {
  cursor: pointer;
}

strong {display: inline-block;}
a {margin:0; padding:0; font-size:100%; }
table {border-collapse:collapse; border-spacing:0;}
input, select {vertical-align:middle; font-size: 16px;}
p {line-height:1.5; word-break: keep-all;}
/* img {width: 100%} */
input {outline:none;}
button {cursor:pointer; border: 0; outline: 0; background: none; font-size: 16px;}
a {text-decoration: none;}
textarea {resize: none;}
.col-group {display:flex;}

pre {
  white-space: pre-wrap;
}

#main:not(.login-page) { 
  position: absolute;
  left: 208px;
  display: flex;
  width: calc(100% - 208px);
}

#main .container { 
  width: 100%;
  background-color: #f5f5f5; 
}

/* header */
#menu { min-height: 100vh;}
.menu { 
  position: fixed;
  min-width: 208px; 
  height: 100vh; 
  overflow-y: auto;
  padding-top: 24px; 
  box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.35); 
  background-color: #001529; z-index: 999;
}
.menu .logo-wrap { display: block; width: 139px; margin: 0 auto 27px; }
.menu .logo-wrap img { width: 100%; }
.menu .nav-list { 
  width: 208px;
}
.menu .nav-list li {  }
.menu .nav-list li .menu-wrap {  padding-left: 24px; height: 40px; display: flex; align-items: center; }

.menu .nav-list li .menu-wrap-fix {cursor: pointer;  padding-left: 12px; opacity: 0.6;  height: 40px; display: flex; align-items: center; cursor: unset;}
.menu .nav-list li .nav-btm { display:none; opacity: 0.6;   padding-left: 64px; height: 40px; display: flex; align-items: center; }
.menu .nav-list li .nav-btm span { font-size: 14px; color: #fff;}

.menu .nav-list li .menu-wrap:hover {  background-color: #0e99ff; opacity: 1;}
.menu .nav-list li .nav-btm:hover { background-color: #0e99ff; opacity: 1; }
.menu .nav-list li .nav-btm.active {  background-color: #0e99ff;  opacity: 1;}

/* 페이지별 네브바 */
.banner-set .menu .nav-list  li:nth-child(1)  { background-color: #0e99ff;  opacity: 1; }
.banner-add .menu .nav-list  li:nth-child(1)  { background-color: #0e99ff;  opacity: 1; }
.individual .menu .nav-list  li:nth-child(2)  { background-color: #0e99ff;  opacity: 1; }
.individual.menu .nav-list  li:nth-child(2)  { background-color: #0e99ff;  opacity: 1; }
.company .menu .nav-list  li:nth-child(3)  { background-color: #0e99ff;  opacity: 1; }
.company  .menu .nav-list  li:nth-child(3)  { background-color: #0e99ff;  opacity: 1; }
.withdrawal  .menu .nav-list  li:nth-child(4)  { background-color: #0e99ff;  opacity: 1; }
.withdrawal  .menu .nav-list  li:nth-child(4)  { background-color: #0e99ff;  opacity: 1; }
.report  .menu .nav-list  li:nth-child(6)  { background-color: #0e99ff;  opacity: 1; }
.report  .menu .nav-list  li:nth-child(6)  { background-color: #0e99ff;  opacity: 1; }
.talk-set .menu .nav-list  li:nth-child(5)  { background-color: #0e99ff;  opacity: 1; }
.transaction-history .menu .nav-list  li:nth-child(7)  { background-color: #0e99ff;  opacity: 1; }
.transaction-history  .menu .nav-list  li:nth-child(7)  { background-color: #0e99ff;  opacity: 1; }
.inquire-set .menu .nav-list  li:nth-child(8)  { background-color: #0e99ff;  opacity: 1; }
.inquire-set  .menu .nav-list  li:nth-child(8)  { background-color: #0e99ff;  opacity: 1; }
.notice-set .menu .nav-list  li:nth-child(9)  { background-color: #0e99ff;  opacity: 1; }
.notice-set  .menu .nav-list  li:nth-child(9)  { background-color: #0e99ff;  opacity: 1; }

/* //페이지별 네브바 */
.menu .nav-list li a { }
.menu .nav-list li .menu-wrap {}
.menu .nav-list li .menu-wrap-fix i { font-size: 14px; color: #fff; margin-right: 12px;}
.menu .nav-list li .menu-wrap i { font-size: 14px; color: #fff; margin-right: 12px;}
.menu .nav-list li .menu-wrap-fix span { font-size: 14px; color: #fff;}
.menu .nav-list li .menu-wrap span { font-size: 14px; color: #fff;}
.menu .header-bottom { 
  width: 208px;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;  
}
.menu .copyright {  display: block; font-size: 11px; opacity: 0.4; color: #fff; margin-bottom: 16px; padding-left: 16px ; line-height: 1.82;}
.menu .logout-btn { width: 100%; height: 40px; display: flex; align-items: center; padding-left: 16px; font-size: 14px; background-color:#170101;}
.menu .logout-btn:hover {  background-color: #0e99ff; }
.menu .logout-btn i { font-size: 16px; color: #fff; margin-right: 16px;}
.menu .logout-btn span {color: #fff; }

.businessFilter {
  width: 90%;
  height: 100%;
  border: none;
  font-size: 0.9rem;
  color: #b5b3b3;
  text-align: center;
  outline: none;
}